<template>
  <div class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div style="background: #0768FD;color: white;display: flex;
      align-items: center;justify-content: center;cursor: pointer;"
           class="width110 font-size16 border-radius4004"
           @click="goBack">
        返回
      </div>
      <div style="color: black;font-weight: bold;display: flex; align-items: center;"
      class="font-size16 margin-left30">
        订单详情
      </div>
    </div>
    <meta content="no-referrer" name="referrer"/>
    <div class="content-box section">
      <easy-card title="订单信息">
        <!-- <icon-button @click.native="goBack" icon="return" slot="extra" /> -->
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 订单编号</template>
            <span>{{ orderDetatil.parkingOrderIntoId }}</span>
            <span class="span-margin" @click="checkPicture">
              <el-link :underline="false" type="primary">查看图片</el-link>
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单状态</template>
            {{ orderStatus[orderDetatil.status] }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 订单来源 </template>

          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 付款状态</template>
            <span>
              <span v-if="orderDetatil.isWhite === 1">无需付款</span>
            <span v-else>{{ orderDetatil.isArrearage == 1 ? "已付款" : "未付款" }}</span>
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            <span>{{ (parseFloat(orderDetatil.receivableAmount)).toFixed(2) }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 交易金额（元）</template>
            <span>{{ (parseFloat(orderDetatil.transactionAmount)).toFixed(2) }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 线上缴费</template>
            <span>{{ (parseFloat(orderDetatil.transactionAmount)).toFixed(2) }}</span>
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 线下缴费 </template>

          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 优惠券抵扣</template>
            <span>{{ (parseFloat(orderDetatil.discountAmount)).toFixed(2) }}</span>
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 抵扣额 </template>

          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 代金券抵扣 </template>
            {{orderDetatilList.discountAmount }}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 岗亭减免 </template>

          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 订单类型 </template> -->
          <!-- <span> {{orderType[orderDetatilList.extendInfo.orderType]}} </span> -->

          <!-- <span class="span-margin" @click="checkBusinessDetail"
            ><el-link type="primary" :underline="false">
              业务详情</el-link
            ></span -->
          <!-- </el-descriptions-item> -->
        </el-descriptions>
      </easy-card>

      <div class="separate-line"></div>

      <easy-card title="车场信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 城区</template>
            {{ orderInfoPlus.regionName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车场名称</template>
            {{ orderDetatil.parkingLotName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 泊位号</template>
            <span v-if="orderDetatil.parkingSpaceCode && orderDetatil.parkingSpaceCode.length>4">
                {{ orderDetatil.parkingSpaceCode.substring(orderDetatil.parkingSpaceCode.length - 4) }}
              </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <!-- <template slot="label"> 岗亭值班人 </template> -->

          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 驶入时间</template>
            {{ new Date(orderDetatil.driveInTime).toLocaleString() }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 驶出时间</template>
            {{ orderDetatil.driveOutTime ? orderDetatil.driveOutTime : '' }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
    </div>

    <!-- 查看图片弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="pictureDialogFormVisible"
        class="common-dialog"
        title="停车图片"
    >
      <div class="showPicture">
        <div class="pictureBox boxF1">
          <el-carousel class="photo-width" height="300px" trigger="click">
            <el-carousel-item v-for="(list, index) in inImgDataList" :key="index" class="photo-item">
              <el-image v-for="(item,index2) in list" :key="index2" :preview-src-list="driveInPhotoList" :src="item"
                        class="top-img"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="table-list">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 车牌号</template>
            <span v-if="orderDetatil.plateNumber != ''">
            <Numplate
                :type="orderDetatil.autoType?orderDetatil.autoType:'02'">
              {{ orderDetatil.plateNumber }}
            </Numplate></span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 停车时长</template>
            {{ orderInfoPlus.parkingTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            <span>{{
                (parseFloat(orderDetatil.receivableAmount)).toFixed(2)
              }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 实收（元）</template>
            <span>{{
                (parseFloat(orderDetatil.transactionAmount)).toFixed(2)
              }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 是否付款</template>
            <span>
            <span v-if="orderDetatil.isWhite === 1">无需付款</span>
            <span v-else>{{ orderDetatil.isArrearage == 1 ? "已付款" : "未付款" }}</span>
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 城区</template>
            {{ orderInfoPlus.regionName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车场</template>
            {{ orderDetatil.parkingLotName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 泊位号</template>
            {{ orderDetatil.parkingSpaceCode }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="parkingDetail"></div>
    </el-dialog>

    <!-- 查看业务详情弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="businessDetailDialogFormVisible"
        class="common-dialog"
        title="业务详情"
    >
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
            align="center"
            fixed="left"
            label="业务类型"
            prop="orderCode"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="变更原因"
            prop="numplate"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>


<script>
import Numplate from "@/components/Numplate";
import {
  getCalcDetailsByParkId,
  searchParkingOrderDetail,
} from "@/api/parkingOrder";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";

export default {
  data() {
    return {
      isLoading: false,
      dataList: [],
      ParkingLotId: '',
      orderDetatil: {}, //从上个页面获取到的订单信息
      orderInfoPlus: {},// 从接口获取的订单详情补充信息
      driveInPhotoList: [],
      driveOutPhotoList: [],
      inImgDataList: [], //路内停车驶入图片
      outImageDataList: [],//
      pictureDialogFormVisible: false, //查看图片弹窗
      businessDetailDialogFormVisible: false, //查看业务详情弹窗
      driveInTableData: [], //驶入记录
      driveOutTableData: [], //驶离记录
      payRecordTableData: [], //支付记录
      orderType: {
        "0": "临停",
        "1": "白名单",
        "2": "黑名单",
        "3": "长租",
        "4": "预存",
        "5": "访客",
        "6": "共享"
      },
      orderStatus: {
        "0": "正常",
        "1": "无效",
        "2": "审核",
        "3": "复议",
        "": "正常",
      },
    };
  },
  components: {
    Numplate,
  },
  methods: {
    //返回停车订单页面
    goBack() {
      this.$router.back();
    },
    //查看停车图片
    checkPicture() {
      this.pictureDialogFormVisible = true;
    },
    checkBusinessDetail() {
      this.businessDetailDialogFormVisible = true;
    },
    //查看证件图片
    checkIdPicture() {
      this.$message({
        message: '暂无图片信息',
        type: 'warning'
      });
    },
    //查看放行凭证
    checkReleasePicture() {
      this.$message({
        message: '暂无图片信息',
        type: 'warning'
      });
    },
    //查询订单计费规则
    getCalcDetailsByParkId() {
      const ParkingLotId = this.ParkingLotId;
      console.log(ParkingLotId);
      getCalcDetailsByParkId(ParkingLotId).then(res => {
        if (res.code === 30 && res.result) {
          console.log(res);
        }
      })
    },
  },
  async mounted() {
    checkToken()
        .then(res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            const result = JSON.parse(sessionStorage.getItem("order_detail"));
            console.log('order_detail', result)
            this.orderDetatil = result;
            this.ParkingLotId = result.parkingLotInfoId;
            searchParkingOrderDetail(result.parkingOrderIntoId).then(res =>{
              if(res && res.code === 30 && res.result){
                this.orderInfoPlus = res.returnObject
                this.inImgDataList = res.returnObject.imageList
                this.driveInPhotoList = res.returnObject.imageList.map(i => i.url)
                // for (let i = this.inImgDataList.length; i < 4; i++) {
                //   this.inImgDataList.push(
                //       this.inImgDataList[0]
                //       // {
                //       //   "creatTime": "",
                //       //   "id": "",
                //       //   "name": "",
                //       //   "url": ""
                //       // }
                //   )
                // }

                // let newDataList = [];
                // let current = 0
                // if (this.inImgDataList && this.inImgDataList.length > 0) {
                //   for (let i = 0; i <= this.inImgDataList.length - 1; i++) {
                //     if (i % 2 !== 0 || i === 0) {	//数据处理成几张展示
                //       if (!newDataList[current]) {
                //         newDataList.push([this.inImgDataList[i]])
                //       } else {
                //         newDataList[current].push(this.inImgDataList[i])
                //       }
                //     } else {
                //       current++
                //       newDataList.push([this.inImgDataList[i]])
                //     }
                //   }
                // }
                // this.inImgDataList = [...newDataList]
              }
            });
            // this.getImgs(result)
          }
        })
  },
};
</script>

<style lang="less" scoped>
.record-tilte {
  margin: 14px 0;
}

.span-margin {
  margin-left: 4px;
}

.showPicture {
  display: flex;
  flex-direction: row;
}

.pictureBox {
  border: 1px solid #bbb;
  width: 100%;
}

.boxF1 {
  margin-right: 10px;
}

.boxF2 {
  margin-left: 10px;
}

.carNumber {
  width: 100%;
  line-height: 30px;
  text-align: center;
  background: #0768FD;
  color: #fff;
}

.inOrOutTime {
  background: #f0f1f5;
  // padding-left: 15px;
  width: 100%;
  display: inline-block;
  line-height: 30px;
}

.photo-width {
  margin: 12px 8px;

  .photo-item {
    width: 100%;
    //display: flex;

    .top-img {
      //width: 284px;
      //height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.table-list {
  margin-top: 20px;
}

/deep/ .el-button--text{
  color: #0768FD !important;
}
</style>
